/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Frontend WEBARQ
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 14px;
    font-family: 'Open Sans';
    position: relative;
    overflow-x: hidden;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }

/* structure 
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}
.center {text-align: center;}
.trigger {position: absolute; left: 0; right: 0; top: 0; bottom: 0; height: 3px; background: transparent; display: block; margin: auto;}
.trigger-2{ top: -15%; }

section { position: relative; }
.btn_round {
    display: inline-block; line-height: 50px; border: none; background: $c_grn; padding: 0 40px; text-align: center; @include borderRadius(25px); color: #fff; font-size: 15px; font-weight: 700;
    cursor: pointer; @include boxSizing(border-box); letter-spacing: 0.5px; position: relative;
    &.red {
        background: #ec1d24;
    }
    &.arr {
        i {width: 24px; height: 24px; display: inline-block; vertical-align: middle; background: url('../images/material/ic_arr.png') no-repeat; margin: 0 -20px 0 5px; @include transition(all ease-out 0.25s);}
        &:hover {
            color: #fff;
            i {@include transition(all ease-out 0.25s); margin: 0 -25px 0 10px; }
        }
        &.back {
            i {@include transform(rotate(180deg)); margin: 0 10px 0 -20px;}
            &:hover {
                i {@include transition(all ease-out 0.25s); margin: 0 15px 0 -25px;}
            }
        }
    }
}
.title-little{
    font-size: 30px; line-height: 1.37; color: #3b3b3b; position: relative;padding-left: 28.5px; box-sizing: border-box; font-weight: 300; margin-bottom: 20px;
    &:before{ position:absolute; content: ""; background: #ec1d24; height: 40px; width: 2px; left: 0; top: 0px; }
    &.np {
        padding-left: 10px; padding-bottom: 20px; border-bottom: 1px solid rgba(195,195,195,0.5); margin-bottom: 44px;
        &:before {top: -3px; height: 35px;}
    }
}
.title-pg {
    font-size: 45px; line-height: 1.36; color: #3b3b3b; position: relative;padding-left: 28.5px; box-sizing: border-box; font-weight: 300; margin-bottom: 20px;
    &:before{ position:absolute; content: ""; background: #ec1d24; height: 60px; width: 2px; left: 0; top: -10px; }
}

/* std-content
----------------------------------------------------------------------------------------------*/
.std-content{
    table,thead,tbody,tfoot,tr,th,td{
        border: 1px solid grey;
    }
    table{
        th,td{padding: 10px;line-height: 1.3;}
        th{font-weight: bold;}
    }
}


/* header
----------------------------------------------------------------------------------------------*/
header{
    position: fixed; left: 0; right: 0; display: block; z-index: 9; @extend .after_clear; height: 78.3px; box-sizing: border-box; top: 0; background: #fff; -webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;transition: all .5s ease-in-out;
    &:before {width: 20%; content: ""; right: 0; height: 100%; position: absolute; display: block; background: $c_ylw;}
    .wrapper{ 
        position: relative; width: auto; padding: 0 45px;
        -webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;transition: all .5s ease-in-out;
        .logo{ width:auto; float: left; position: relative; left: 50px; margin-top: 18px;}
        .rh_head { 
            float: right; position: relative; z-index: 3; width: auto; padding-top: 31px; box-sizing: border-box; margin-right: 10px; padding-left: 50px; background: $c_ylw;
            nav{
                @extend .after_clear;
                a{ 
                    font-size: 15px; font-weight: normal;line-height: 1.33;text-align: center; color: #525252; height: 47px; width: 100%;
                    
                }
                > ul {
                    margin-right: 40px;
                    > li {padding: 0 12px; margin: 0 10px;}
                }
                ul {
                    float: left;
                    &.mmobile{ display: none; }
                }
                ul li{ 
                    position: relative; float: left; text-align: center;
                    &:hover, &.active{ 
                        position:relative;
                        a{ 
                            color: #ec1d24;
                            &:before{ background: #ec1d24;}
                        }
                    }
                    &.active {
                        a {font-weight: 600; }
                    }
                    a{ 
                        display: block; 
                         &:before{ content:""; position: absolute; width: 100%; height: 2px; background: transparent; top: -31px; left: 0;}
                    }
                    .subdrop{
                        display: none; position: absolute; left: 50%; min-width: 230px; max-width: 230px; min-height: 50px; background: $c_ylw; padding-bottom: 10px; box-sizing: border-box;
                        @include transform(translateX(-50%)); 
                        ul {
                            li {
                                display: block; margin: 0; padding: 0 27px; text-align: center; float: none;
                                &:hover, &.active{ 
                                    &:before{ display:none; }
                                }
                                a {
                                    padding: 7px 0 7px 0; display: block; font-size: 14px; text-transform: capitalize; height: auto; text-align: left;
                                    color: #333; line-height: 16px; font-weight: 600; 
                                    &:hover{ color: #ec1d24; }
                                    &:before {display: none;}
                                }
                                &.subdropdown {                                 
                                    ul {
                                        position: absolute; top: 0; left: 100%; min-width: 200px; max-width: 230px;
                                        display: none;  
                                    }
                                }
                            }
                        }
                    } 
                }
                .thumb-header{ 
                    float: left; margin-right: 0;
                    .col{ display: inline-block; vertical-align: top; }
                    .language{
                        padding-right: 15px; position: relative;
                        &:before{
                            position:absolute; content: ""; background: #ec1d24; height: 20px; width: 1px; right: 0;
                        }
                        .vodiapicker{ display: none; }
                        #list-ul-lang{
                            padding-left: 0; cursor: pointer;
                            li{ 
                                list-style: none; cursor: pointer; float: none; text-align: left; padding: 5px 20px 5px 0;
                                &.active{ display: none; }
                                &:before{ display:none; }
                                img{ margin: 5px; }
                            }
                        }
                        #a li span, .btn-select li span{ margin-left: 10px; }
                        #list-ul-lang img, .btn-select img{ width: 18px; vertical-align: middle; }
                        .list-lang{ display: none; background: $c_ylw; color: #333; font-weight: bold; padding-left: 1px; box-sizing: border-box; position: absolute; top: 47px; }
                        .open{ display: show !important }
                        .btn-select{ 
                            width: 100%; max-width: 350px; height: 21px; border: none; background: transparent; position: relative; font-size: 15px; font-weight: bold; line-height: 1.33;text-align: left; color: #ec1d24; cursor: pointer;
                            @extend .after_clear;
                            &:after{ position:absolute; content: ""; background: url(../images/material/arrow-down-red.png) no-repeat center right; width: 15px; height: 20px; right: -10px; }
                            &:before{
                                li{ margin-left:0; }
                            }
                            li{ list-style: none; float: left; padding-bottom: 0; }
                        }
                    }
                    .sign{
                        margin-left: 10px;
                        @extend .after_clear;
                        a{ 
                            font-size: 15px; line-height: 1.3; color:#ec1d24; float: left; width: auto;
                            img{ margin-left: 10px; margin-top: -28px; padding-top: 25px;  box-sizing: border-box; vertical-align: top; }
                        }
                    }
                }
            }
        }
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.middle {
    padding-top: 78px; position: relative; overflow: hidden;
    .wrapper{ width: auto; margin: 0 100px; }
    &.pattern-right{
        position: relative;
        &:before{ position:absolute; content:""; background: url(../images/material/bgmisi.png); width: 265px; height: 456px; top: 700px; right: 0; }
    }
    &.no_over {padding-bottom: 150px; margin-bottom: -150px;}
}
.bg_btm_rfull {background: $c_ylw; height: auto; width: 50%; z-index: -1; position: absolute; top:0px; right: -100px; bottom: -250px;}
.bg-yellow-homepage{ background: #FFFF00; height: 858px; width: 50%; z-index: -1; position: absolute; top:0px; right: 0;}
.bg-yellow-small{ background: $c_ylw; height: 642px; width: 963px; z-index: -1; position: absolute; top:0px; right: 0;}
.bodysec{
    padding-top: 78.3px; position: relative;box-sizing: border-box; overflow: hidden;
    .home-slide{
        position: relative;
        .wrapper{
            width: 1713px; margin: 0 auto;position: relative;
            .slider{
                .slide{
                overflow: hidden;
                    position: relative;
                    .images{ width: 100%; }
                    &:before{ position: absolute; content: ""; background: url(../images/material/bg-shadow-slider.png); height: 100%; width: 100%; z-index: 0;}
                    .caption{
                        position: absolute;top: 50%; transform: translateY(-50%); left: 105px;
                        h5{ 
                            font-size: 15px; color: $c_grn; font-style: italic; line-height: 1.3; position: relative; margin-bottom: 10px;
                            &:after {content:""; display: inline-block; vertical-align: middle; height: 1px; background: $c_grn; width: 50px; margin-left: 15px;}
                        }
                        h1{
                            font-size: 60px; line-height: 1.37; color: #fff; margin-top: 6px; font-weight: 300;                   }
                        p{
                            font-family: "Poppins"; font-size: 15px; line-height: 1.53; color: #fff;
                        }
                    }
                }
                .slick-dots{
                    position: absolute; top: 50%; transform: translateY(-50%);  right: -70px; width: 50px;
                    @extend .after_clear;
                    li{
                        position: relative; display: block;line-height: 1.35; padding: 13px; text-align: center;  width: 50px; height: 50px; box-sizing: border-box; font-size: 17px; background: $c_ylw; color: #fff;
                        &.slick-active{ background: #929200}
                        button{ 
                            border: none;background: none;color: #fff; position: relative;font-size: 17px; padding:0;vertical-align: top; 
                            &:before{ position:relative; display: -moz-inline-stack; display: inline-block; content: "0"; top: 0px; left: 0px; color: #fff; font-size: 17px;}
                        }
                    }
                }
            }
            .nav_slide{ 
                position: absolute; bottom: 0; width: 859px; height: 160px; background: #fff; right: 0;
                .col{
                    .list{
                        margin: 32px 0; padding: 0 30px; box-sizing: border-box;position: relative; cursor: pointer; min-height: 80px;
                        p{ color: #717171; font-size: 15px; height: 100px; overflow: hidden; }
                        &:after{ position:absolute; content: ""; height: 70%; width: 1px; background: #717171; right: 0; bottom: 0; top: 0; margin: auto; }
                        &.slick-current{
                            p{ font-weight: bold;  }
                        }
                    }   
                }
            }
            .socmed{ 
                position: absolute; bottom: 51.7px; left: 111.1px; z-index: 9; 
                ul li{
                    display: inline-block; position: relative;  margin-right: 20px;
                    a{ 
                        font-size: 15px; font-style: italic; line-height: 1.33; color: #fff; padding-left: 20px; padding-right: 70px; cursor: pointer; z-index: 9; display: block; pointer-events: visible; cursor: pointer; position: relative; display: inline-block; 
                        img{ vertical-align: middle; margin-right: 5px; }
                        &:before{ position: absolute; content:""; left: 0; width: 100%; height: 100%; }
                        &:after{ position: absolute; content:""; right: 0px; top: 10px; width: 40px; height: 1px; background: #fff }
                    }
                }
            }
        }
        .navdots{
            position: absolute; top: 50%; transform: translateY(-50%);  right: 20px; width: 50px;
            @extend .after_clear;
            .slick-slide{
                position: relative; display: block;line-height: 1.35; padding: 13px; text-align: center;  width: 50px; height: 50px; box-sizing: border-box; font-size: 17px; background: $c_ylw; color: #fff;
                &:before{ position:relative; display: inline-block; content: "0"; top: 0px; left: 0px; color: #fff; font-size: 17px;}
                &.slick-current{ background: #dada00}
            }
        }
        .scroll-down{ position: absolute; right: 28.7px; bottom: 36.5px; }
    }
    .title{
        font-size: 45px; line-height: 1.36; color: #3b3b3b; position: relative;padding-left: 28.5px; box-sizing: border-box; font-weight: 300; margin-bottom: 20px;
        &:before{ position:absolute; content: ""; background: #ec1d24; height: 60px; width: 2px; left: 0; top: -10px; }
    }
    .home-about{
        position: relative; padding-top: 120px; box-sizing: border-box;
        &:before{ position:absolute; content: ""; background: $c_ylw; height: 410px; width: 484px; top: 0; left: 95px; z-index: -1;}
        .left{ 
            width: 696px; margin-left: 228px; 
            .images{
                img{ border-radius: 5px; }
            }
        }
        .right{ 
            width: 719.5px; margin-right: 210px; margin-top: 53px;
            .content{
                position: relative;
                &:after{ position:absolute; content: ""; background: url(../images/material/pattern2.png) no-repeat; height: 100%; width: 213px; right: 0; top: 0; }
                h5{ margin-left: 28.5px; font-size: 15px; font-weight: bold; line-height: 1.33; color: #717171; margin-bottom: 29px; }
                p{ margin-left: 28.5px; font-size: 15px; line-height: 1.53; color: #717171; font-family: "Poppins"; overflow: hidden; height: 90px; margin-bottom: 41px;}
                .btn_round{ margin-left: 28.5px; }
            }
        }
        .tag{ font-family: "Poppins"; font-size: 90px; font-weight: bold; line-height: 1.51; letter-spacing: 9px; color: #5a5a5a; text-transform: uppercase; position: absolute; @include transform(rotate(-90deg)); right: -310px; bottom: -88.5px; transform-origin: 0px 0px; top:auto; position: absolute; z-index: 3; width: auto; display: block; margin: 0; padding-right: 20px; white-space: nowrap; opacity: 0.04; }
    }
    .home-service{
        margin:125px 236.8px 0 228px;
        .highlight{
            margin-bottom: 35px;
            @extend .after_clear;
            .left{}
            .right{ 
                width: 665px; 
                p{ overflow: hidden; height: 45px; font-size: 15px; line-height: 1.53; color: #717171; }
            }
        }
        .box-layanan{
            z-index: 3;
            @extend .after_clear;
            .list{
                float: left; margin: 0 6px; position: relative; z-index: 3; width: 32%;
                .images{
                    width: auto; height: auto; border-radius: 5px; position: relative;
                    &:before{ position: absolute; content: ""; background: url(../images/material/bg-shadow-layanan.png); width: 100%; height: 100%;  background-size: 100%; z-index: 1; bottom: -2px;}
                    img{ 
                        width: auto; height: auto; overflow: hidden; object-fit: cover; border-radius: 5px;
                    }
                }
                .content{ 
                    position: absolute;bottom: 0; z-index: 2; left: 50px; bottom: 50.4px; right: 50px;
                    @extend .after_clear;
                    .left{ 
                        width: 280px;  
                        h4{ color: #fff; font-size: 30px; line-height: 1.37; height: 125px; overflow: hidden; }
                    }
                    .right{
                        .btn-circle{
                            position: relative; z-index: 5;
                            &:before{ position:absolute; content: ""; background: url(../images/material/circle-plus.png) no-repeat; width: 55px; height: 57px; right: 0; top: 0px; background-size: 100%; }
                        }
                    }
                }
            }
            .slick-arrow{ 
                position: absolute; right: -60px; bottom: 140px; border:none; background: none; font-size: 0; width: 35px; height: 35px; cursor: pointer; z-index: 1;
                &.slick-next{ 
                    background: url(../images/material/arrowright-grey.png) no-repeat; background-size: 100%;
                    &:before{ position:absolute; content: ""; background: #999; height: 1px; width: 100%; top: -5px; left: 0; }
                }
                &.slick-prev{ background: url(../images/material/arrowleft-grey.png) no-repeat; background-size: 100%; bottom: 190px; }
                &.slick-disabled{ opacity: 0.69; cursor: default; }
            }
        }
    }
    .home-promote{
        .wrapper{ 
            position: relative; 
            &:before{ position: absolute; content: ""; background: $c_ylw; height: 510px; width: 100%; bottom: 0; z-index: -1; }
        }
        .slider{
            margin: -106px 100px -100px 100px;z-index: 2;
            .slide{
                position: relative;  
                .content{ 
                    position: absolute; right: 155px;top: 50%; transform: translateY(-50%); 
                    .title{ margin-bottom: 30px; }
                    .btn_round{ margin-left: 28.5px; }
                }
            }
        }
    }
    .home-news{ 
        background: #43bac7; position: relative;
        &:after{ position:absolute; content: ""; background: url(../images/material/pattern-news.png) no-repeat; width: 358px; height: 430px; top: 50%; transform: translateY(-50%); right: 0;}
        &:before{ position:absolute; content: ""; background: url(../images/material/bg-shadow.jpg) no-repeat; width: 100%; height: 100%; opacity: 0.1; }
        .wrapper{ 
            padding-top: 165px; padding-bottom: 122.6px ; margin: 0 234px 0 224.5px; box-sizing: border-box;
            .title{ color: #fff; }
            .list{
                width: 33%; display: inline-block; padding-right: 20px; box-sizing: border-box; border-left: 1px solid; border-color: rgba(255, 255, 225, 0.4);
                .content{ 
                    margin-left: 28.5px; color: #fff;
                    h3{ font-size: 45px; font-weight: bold; line-height: 1.36; margin-bottom: 28px; }
                    h5{
                        font-size: 15px; color: #ffd257; font-style: italic; line-height: 1.53; position: relative; margin-bottom: 11px;
                        &:after {content:""; display: inline-block; vertical-align: middle; height: 1px; background: #ffd257; width: 50px; margin-left: 15px;}
                    }
                    h4{ font-size: 20px; font-weight: bold; line-height: 1.35; margin-bottom: 20px; height: 60px; overflow: hidden; }
                    p{ font-family: "Poppins"; font-size: 15px; line-height: 1.53;height: 45px; overflow: hidden; margin-bottom: 30px;}
                }
            }
            .col{
                position: relative;
                .slick-arrow{ 
                    position: absolute; right: -30px; bottom: 0; border:none; background: none; font-size: 0; width: 35px; height: 35px; cursor: pointer; z-index: 1;
                    &.slick-next{ 
                        background: url(../images/material/arrowright-white.png) no-repeat; background-size: 100%;
                        &:before{ position:absolute; content: ""; background: rgba(255,255,255,0.2); height: 1px; width: 100%; top: -5px; left: 0; }
                    }
                    &.slick-prev{ background: url(../images/material/arrowleft-white.png) no-repeat; background-size: 100%; bottom: 48px; }
                    &.slick-disabled{ opacity: 0.69; cursor: default; }
                }
            }
        }
    }
    .home-client{
        margin-top: 100px; margin-bottom: 90px; position: relative;
        .tag{ font-family: "Poppins"; font-size: 90px; font-weight: bold; line-height: 1.51; letter-spacing: 9px; color: #5a5a5a; text-transform: uppercase; position: absolute; @include transform(rotate(-90deg)); right: 0px; bottom: -88.5px; transform-origin: 0px 0px; top:auto; position: absolute; z-index: 3; width: auto; display: block; margin: 0; padding-right: 20px; white-space: nowrap; opacity: 0.04; }
        .wrapper{
            @extend .after_clear;
            .left,.right{ width: 50%; }
            .left{ 
                padding-left: 161.5px; box-sizing: border-box; 
                .slider{
                    position: relative;
                    &:before{ position:absolute; content: ""; height: 1px; width: 53px; background: rgba(0, 0, 0, 0.1); top: 50%; left: -50px;  }
                    &:after{ position:absolute; content: ""; height: 1px; width: 53px; background: rgba(0, 0, 0, 0.1); top: 50%; right: -35px;  }
                    .slick-arrow{ 
                        position: absolute; left: -48px; bottom: 50%; border:none; background: none; font-size: 0; width: 35px; height: 35px; cursor: pointer;
                        &.slick-next{ 
                            background: url(../images/material/arrowright-grey.png) no-repeat; background-size: 100%;  bottom: 138px;
                        }
                        &.slick-prev{ background: url(../images/material/arrowleft-grey.png) no-repeat; background-size: 100%; }
                        &.slick-disabled{ opacity: 0.69; cursor: default; }
                    }
                    .slide{
                        .col{
                            @extend .after_clear;
                            .list{
                                float: left; position: relative; border:1px solid; border-color: rgba(0, 0, 0, 0.1); width: 385px;
                                &:nth-child(1){ border-right: 0; border-top: 0; }
                                &:nth-child(2){ border-top: 0; }
                                &:nth-child(3){ border-right: 0; border-bottom: 0; border-top: 0; }
                                &:nth-child(4){ border-bottom: 0; border-top: 0;}
                                .images{
                                    img{ max-height: 172px; width: auto; }
                                }
                            }
                        }
                    }
                }
            }
            .right{
                width: 510px; margin-right: 299px; box-sizing: border-box; margin-top: 100px;
                p{ font-size: 15px; font-family: "Poppins"; line-height:1.53; color: #9f9f9f; overflow: hidden; height: 70px; }
            }
        }
    }
    .home-map{
        .wrapper{
            position: relative; width: auto;
            &:before{ position:absolute; content: ""; background: #2e2e2e; box-sizing: border-box; height: 200px; width: auto; right: 100px; left:100px; bottom: 0; z-index: -1; margin: 0 auto; }
            
        }
        .map{ 
            width: 1456px; height: 520px; margin: 0 auto; 
            iframe{ width: 100%; height: 100%;}
        }
    }
}
.btn{
    font-size: 15px; font-weight: bold; line-height: 1.33; color: #fff; padding: 13px 63px 14px 38px; border-radius: 27px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); position: relative;
    &:before{ position:absolute; content: ""; background: url(../images/material/arrowright-white.png) no-repeat right; height: 100%; width: 100%; top: 0; right: 36px;}
    &.blue{ background:#43bac7; } 
}
.banner {
    position: relative;
    figure {
        position: relative; overflow: hidden;
        &:after {
            content:""; left: 0; right: 0; top: 0; bottom: 0; margin: auto; display: block; background: rgba(0,0,0,0.45); height: 100%; width: 100%;
            position: absolute;
        }
        img {display: block; width: 100%;}
    }
    .capt_abs {
        position: absolute; top: 50%; @include transform(translateY(-50%)); width: 700px; left: 105px; opacity: 0.8;
        h6 {
            font-size: 15px; color: $c_grn; font-style: italic; line-height: 1.3; position: relative; margin-bottom: 10px;
            &:after {content:""; display: inline-block; vertical-align: middle; height: 1px; background: $c_grn; width: 50px; margin-left: 15px;}
        }
        h1 {font-size: 60px; color: #fff; margin-bottom: 10px; line-height: 1.37; font-weight: 300;}
        p {margin: 0; font-size: 15px; color: #fff; font-family: "Poppins"}
    }

}
.mini-banner-content{
    margin-bottom: 50px; position: relative;
    @extend .after_clear;
    .images{
        img{ border-radius: 5px; }
    }
    .tag{ 
        font-family: "Poppins"; font-size: 130px; font-weight: bold; letter-spacing: 9px; color: #5a5a5a;
        text-transform: uppercase; position: absolute; @include transform(rotate(-90deg)); left:0px; top:510px; bottom: auto;
        transform-origin: 0px 0px; z-index: 3; width: auto; display: block; margin: 0; padding-right: 20px; 
        white-space: nowrap; opacity: 0.04; 
       &:before{ position:absolute; content:''; right: 0; bottom: 0; top: 0; width: 265px; height: 100%; 
            background: url(../images/material/pattern2.png)no-repeat center; display: block; z-index: 5;  }
    }
}
.inner-content {
    padding: 120px 125px 0; position: relative;
    @extend .after_clear;
    &.no_padding {
        padding: 0;
    }
    p{ font-size: 15px; line-height: 23px; color: #717171; font-family: "Poppins"; margin-left: 30px;}
    .bg_top_left {
        width: 484px; height: 410px; background: $c_ylw; position: absolute; top: 0; left: 0;z-index: -1; display: block;
        &.long{ height: 1010px; }
    }
    .bg_wht_top {position: absolute; height: auto; background: #fff; position: absolute; left: -1px; top: -70px; width: 66.7%; display: block; bottom: -100px;}
    .tittle-content{
         position: relative; margin-bottom: 30px;
        b{ display: block; }
        &.inline{
            b{ display: inline-block; }
        }
       h2{
         line-height: 61px;font-size: 45px; padding-left:  28.5px; color: #3b3b3b;
         &:before{ content:''; position: absolute; left:0;top: -13px; width: 3px; height: 62px; background-color: #ec1d24; }
       }
    }
    .wrapper{  
        width: 1456px;
        @extend .after_clear;
    } 
    .content{ margin-bottom: 94.5px; }
    .banner-top{ margin-bottom: 45px; }
}
.content-style{
    ul{
        display: block; margin-left: 30px;
        li{
            position: relative; font-size: 15px; line-height: 23px; padding-left: 20px; box-sizing: border-box; margin-bottom: 15px; color: #717171; font-family: "Poppins";
            &:before{ position:absolute; content: ""; width: 5px; height: 5px; background: #ec1d24; left: 0; top: 13px; transform: translateY(-50%); border-radius: 100%;}
        }
    }
    ol{
        margin-left: 40px;
        li{
            list-style: decimal; position: relative; font-size: 15px; line-height: 23px; padding-left: 10px; box-sizing: border-box; margin-bottom: 15px; color: #717171; font-family: "Poppins";
        }
    }
    table{
        width: 100%; font-family: "Poppins"; color: #717171; margin: 20px 0; overflow-x: auto;width: fit-content; display: block;
        thead{
            tr{ 
                border:1px solid #E2E2E2;
                th{ font-weight: 600; padding: 40px 20px; text-align: left;}
            }
        }
        tbody{
            tr{
                border:1px solid #E2E2E2;
                td{ padding: 20px; }
            }
        }
    }
}
.info-sign{ 
    margin-top: 24px; font-family: "Poppins"; font-style: italic; line-height: 1.54; color: #717171;text-align: center;
    a{ color: #43bac7; font-weight: bold; }
}
.box-form {
    .row {
        margin: 0 -10px 25px; @extend .after_clear;
        @extend .after_clear;
        label {font-size: 15px; line-height: 1.5; font-family: $f_popin; font-weight: 600; color: $c_71; letter-spacing: 1px; display: block; margin-bottom: 10px; padding: 0 27px;} 
        input[type="text"],input[type="email"],input[type="number"],input[type="password"],textarea,select {
            padding: 14px 27px; font-size: 15px; line-height: 1.53; display: block; @include boxSizing(border-box); width: 100%; border: none; @include borderRadius(25px); font-weight: 600; letter-spacing: 0.5px; font-family: "Poppins";
            &.error {
                border: 1px solid #f2685d;
            }
        }
        .input_file{
            padding: 14px 27px; font-size: 15px; line-height: 1.53; display: block; @include boxSizing(border-box); width: 100% !important; border: none; @include borderRadius(25px); font-weight: 600; letter-spacing: 0.5px; font-family: "Poppins"; position: relative; color: #666;
            input[type='file'] {
                width: 100%; @include boxSizing(border-box); z-index: 4;
                &.error {
                    + label {
                        + span {
                            + small {border-color: #f2685d;}
                        }
                    }
                    + span {
                        + small {border-color: #f2685d;}
                    }
                }
            }
            &:after{ content: ''; background: url('../images/material/upload.png')no-repeat right 0; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); width: 24px; height: 24px; color: #f00;
            }
            span{ font-style: italic; color: #c3c3c3; letter-spacing: 0.5px; font-weight: 300; white-space: nowrap; display: block; overflow: hidden;text-overflow: ellipsis; padding-right: 25px;}
            &.nempty{
                span{ font-size: 15px; line-height: 1.53; color: #666; font-weight: 600; letter-spacing: 0.5px; font-family: "Poppins"; font-style: normal; }
            }
            label.error{ position: absolute; bottom: -35px; left: 0; }
            small {position: absolute; left: 0; right: 0; top: 0; bottom: 0;display: block; border: 1px solid #eaeaea; @include borderRadius(24px);z-index: 1;}
        }
        select{ font-weight: normal; color: #717171; background: #fff url('../images/material/arr_select.png') no-repeat 92% center; padding-right: 35px; }
        textarea {height: 150px; resize: none; @include borderRadius(20px);}
        @include placeholder {font-style: italic; color: #c3c3c3; letter-spacing: 0.5px; font-weight: 300;}
        .col {
            padding: 0 10px; @include boxSizing(border-box); float: left; width: 100%;
            &.col5 {
                width: 50%;
                .btn_round {width: 100%; margin-top: 30px;}
            }
        }
    }
    &.brder {
        .row {
            margin: 0 0 35px;
            .input_file,input[type="text"],input[type="email"],input[type="number"],input[type="password"],textarea,select {
                border: 1px solid #eaeaea; padding: 12px 27px; @include boxShadow(0 0px 6px -2px rgba(0,0,0,0.075));
                &.error {
                    border: 1px solid #f2685d;
                }
            }
            .input_file {
                height: 48px !important; border: none; 
                input[type="file"] {
                    &.error {
                        + label {
                            + span {
                                + small {border-color: #f2685d;}
                            }
                        }
                        + span {
                            + small {border-color: #f2685d;}
                        }
                    }
                }
            }
            .input_file:has( > .error ) {border-color: red;}
            select {padding-right: 35px; background-position: 96%;}
            .col {
                padding: 0;
                &.col5 {
                    width: 47%; margin-right: 6%;
                    &:nth-child(2n) {margin-right: 0;}
                }
                .btn_round {min-width: 250px; width: 250px;}
            }
            &:last-child {margin-bottom: 0;}
        }
    }
                
}
.packet-box{
    .col{
        @extend .flex-list; width: 100%;
        .list{
            width: 432px; height: auto; margin-right: 66.2px; position: relative; padding: 50px 50px 150px 50px; box-sizing: border-box; margin-bottom: 74px; border-radius: 5px;
            &:before{ position:absolute; content: ""; background: #fff url(../images/material/bg-packet.png) no-repeat; height: 100%; width: 100%; left: 0; top: 0; background-size: cover; z-index: -1; @include transition(all ease-out 0.25s);}
            &:nth-child(3n+3){ margin-right: 0; }
            &:hover{
                @include transition(all ease-out 0.25s);
                &:before{ background: #43bac7 url(../images/material/bg-packet.png) no-repeat; @include transition(all ease-out 0.25s);}
                .content{
                    ul li{ color: #fff; }
                    b{ color: #fff; }
                }
                .title{ color: #fff; }
                .btn_round{ 
                    background: #fff; color: #ec1d24;
                    &.arr{
                        i{ background: url(../images/material/ic_arr_red.png); }
                    }
                }
            }
            .content{
                ul {
                    margin-bottom: 20px; display: block;
                    li{ margin-bottom: 0; line-height: 2; }
                }
                b{ 
                    font-size: 16px; font-family: "Poppins"; line-height: 1.56; color: #4e4e4e; font-weight: 600;                     
                }
            }
            .not-price{ 
                position: absolute; bottom: 100px; left: 50%; transform: translateX(-50%); font-size: 20px; line-height: 1.35; color: #525252; text-align: center; padding: 0 20px; box-sizing: border-box;
                &:after{ position:absolute; content: ""; background:#ec1d24; height: 2px; width: 100%; top: 50%; left: 0; }
            }
            .btn_round{ position: absolute; bottom: 30px; left: 50%; transform: translateX(-50%); }
        }
    }
}
.quote{
    margin: 60px 133px 0 74px; position: relative; height: auto; padding: 110px 119px 80px 149px; box-sizing: border-box;
    &:before{ position:absolute; content: ""; background: url(../images/material/bg-packet.png) no-repeat; height: 100%; width: 100%; left: 0; top: 0; background-size: cover; z-index: -1; background-position: left top; }
    h4{ 
        font-family: "Poppins", font-size20px; font-weight: 500; font-style: italic;line-height: 1.5; text-align: center;color: #717171; position: relative; opacity: 0.4;
        &:before{ position:absolute; content:"\0022"; font-size: 200px; font-weight: bold;color: #3b3b3b; top: -100px; left: -50px; opacity: 0.06;}
        &:after{ position:absolute; content:"\0022"; font-size: 200px; font-weight: bold;color: #3b3b3b; top: 0px; right: -50px; opacity: 0.06;}
    }
}
.headline-content{
    margin-bottom: 60px;
    @extend .after_clear;
    .left{
        .title{
            font-size: 45px; line-height: 1.36; color: #3b3b3b; position: relative;padding-left: 28.5px; box-sizing: border-box; font-weight: 300; margin-bottom: 20px;
            &:before{ position:absolute; content: ""; background: #ec1d24; height: 60px; width: 2px; left: 0; top: -10px; }
        }
    }
    .right{
        /*width: 848px; */
        p{ overflow: hidden; height: 45px; font-size: 15px; line-height: 1.53; color: #717171; }
        .box-form{
            .row{
                label{ margin-top: 15px; }
                select{ 
                    border: 1px solid rgba(113,113,113,0.29); width: 204px; background: url(../images/material/ic-keyboard-arrow-down-48px.png) no-repeat  80%; margin-left: 27px;
                }
            }
        }
    }
    .row{
        .col{
            width: auto;
        }
    }
}
.info-periode{ margin-bottom: 30px; }
.box-blog{
    @extend .flex-list;
    .col{
        width: 32%; margin-right: 20px;
        &:nth-child(3n+3){ margin-right: 0; }
        @extend .after_clear;
        .content{
            padding: 50px 10px; box-sizing: border-box;
            @extend .after_clear;
            .left{ 
                width: 20%; position: relative; padding-right: 21.5px; box-sizing: border-box;
                &:after{ position:absolute; content: ""; height: 100%; width: 1px; background: #9f9f9f; top: 0; right: -5px; opacity: 0.6; } 
            }
            .right{ width: 80%; padding-left: 35px; box-sizing: border-box;}
            h3{ 
                font-family: "Poppins"; font-size: 30px; font-weight: 100; line-height: 1.5; color: #9f9f9f; text-align: center;
            }
            h4{ font-size: 24px; font-weight: bold; line-height: 1.35; color: #404040; margin-bottom: 10px; overflow: hidden; height: 70px;}
            p{ font-family: "Poppins"; font-size: 15px; line-height: 1.53; color: #9f9f9f; overflow: hidden; height: 45px; margin-left: 0; }
        }
    }
}
.box-event{
    @extend .flex-list;
    .list{
        width: 33.3%; padding-right: 20px; box-sizing: border-box; border-left: 1px solid; border-color: rgba(0, 0, 0, 0.09);
        .content{ 
            margin-left: 28.5px;
            h3{ font-size: 45px; font-weight: bold; line-height: 1.36; margin-bottom: 28px; color: #cecece; }
            h5{
                font-size: 15px; color: #43bac7; font-style: italic; line-height: 1.53; position: relative; margin-bottom: 11px;
                &:after {content:""; display: inline-block; vertical-align: middle; height: 1px; background: #43bac7; width: 50px; margin-left: 15px;}
            }
            h4{ font-size: 20px; font-weight: bold; line-height: 1.35; margin-bottom: 20px; color: #404040; height: 55px;}
            p{ font-family: "Poppins"; font-size: 15px; line-height: 1.53;height: 45px; overflow: hidden; margin-bottom: 30px; color:#9f9f9f; margin-left: 0;}
        }
        .images {
            display: block; overflow: hidden; position: relative;
            img {width: 100%;@include transition(all ease-out 0.3s); position: relative; display: block;}
        }
        &:hover {
            .images {
                img {@include transition(all ease-out 0.5s); @include transform(scale(1.1));}
            }
        }
        &.vids{
            cursor: pointer;
            .images {
                &:after {width: 70px; height: 70px; background: rgba(0,0,0,0.5) url('../images/material/play.png') no-repeat center; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; content: ""; @include borderRadius(50%); background-size: 20px; @include transition(all ease-out 0.75);}
            }
            &:hover {
                .images:after {@include transition(all ease-out 0.75);}
            }
        }
    }
    &.gallerypages{
        .list{
            margin-bottom: 30px;
            .content{
                margin-bottom: 20px;
                h5{ margin-top: 30px; }
            }
        }
    }
}
.pagination{
    position: relative; text-align: center; box-sizing: border-box; margin-bottom: 95px; margin-top: 77px;
    a{
        position: relative; display: inline-block; font-size: 15px; color: #9f9f9f; vertical-align: middle; height: 27px; width: 27px; text-align: center;line-height: 27px; font-weight: 500; font-family: "Poppins";
        &.active{ 
            color:#43bac7; 
        }
        &.arr {
            background: #ec1d24; position: relative; border-radius: 100%; margin: 0 20px;
            &.next {
                span{
                    background: url(../images/material/arr-next.png) no-repeat center; width: 16px; height: 16px; position: absolute; transform: translate(-50%,40%); -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1);
                }
            }
            &.prev { 
                span{
                    background: url(../images/material/arr-prev.png) no-repeat center; width: 16px; height: 16px; position: absolute; transform: translate(-50%,40%); -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1);
                }
            }
            &.disabled{ 
                background: #eaeaea;
                &.prev, &.next{
                    span{ -webkit-filter: none; filter: none; }
                }
            }
        }
    }
}
.milestone{
    margin-bottom: 135px;
    .col{
        position: relative;
        h3{ 
            font-family: "Poppins"; font-size: 38px; font-weight: 600; line-height: 1.53; color: #43bac7; position: absolute; right: 50%; transform: translateX(50%); top: 0;
            &:after{ position:absolute; content: ""; background: #e2e2e2; width: 16px; height: 16px; border-radius: 100%; bottom: -11px; left: 50%; transform: translateX(-50%); }
        }
        .box{
            padding-top: 60px; box-sizing: border-box; position: relative; padding-bottom: 20px;
            @extend .after_clear;
            &:before{ position:absolute; content: ""; background: #e2e2e2; width: 1px; height: 85%; bottom: 0; right: 50%; transform: translateX(-50%); }
            .right{ 
                width: 50%;
                @extend .after_clear;
                .content{
                    width: 662px; margin-top: 19px;
                    ul li{
                        position: relative; list-style: none; margin: 0; padding-left: 96.5px; box-sizing: border-box;
                        &:before{ position:absolute; content: ""; background: #e2e2e2; width: 12%; height: 1px; bottom: 50%; left: 0; transform: translateY(-50%); z-index: -1; }
                        h5{ font-family: "Poppins"; font-size: 17px; font-weight: 600; line-height: 1.53; color: #717171; margin-bottom: 10px; }
                        p{ font-family: "Poppins"; font-size: 15px; line-height: 1.53; color: #717171; margin-left: 0; }
                    }
                }
            }
            .left{
                width: 50%; position: relative; padding-left: 148.5px; box-sizing: border-box;
                &:before{ position:absolute; content: ""; background: #e2e2e2; width: 50%; height: 1px; bottom: 50%; right: 0; transform: translateY(-50%); z-index: -1; }
                .images{
                    width: 485px; height: 341px;
                }
            } 
        }
        &:nth-child(even){
            .right{
                float: left;
                @extend .after_clear;
                .content{
                    float: right;
                    ul li{
                        padding-left: unset; padding-right: 96.5px;
                        &:before{ left:unset; right: 0; }
                    }
                }
            }
            .left{
                float: right; padding-left: 82.5px;
                &:before{ right:unset; left: 0; }
            }
        }
        &:nth-child(odd){
            .right{
                .content{
                    ul{ margin-left: 0; }
                }
            }
        }
    }
    tooltip{
        position: relative; display: inline-block; color: #717171;
        span{ 
            visibility: hidden; width: 300px; background: #555; color: #fff; text-align: center; border-radius: 5px; padding: 5px 0; position: absolute; z-index: 1; bottom: 100%; left: 50%; margin-left: -100px; 
        }
        &:active,&:hover,&:focus{
            span{ visibility: visible; width: 300px; display: block; }
        }
    }
}
.v-text {position: relative; @include transform(translateY(-50%)); top: 50%;}
.wrap_signin {
    padding-top: 78px; @extend .flex-list;
    .img {
        padding-left: 100px; float: left; width: 53.7%; @include boxSizing(border-box); position: relative;
        img {display: block; @include boxShadow(10px 5px 20px -10px rgba(0,0,0,0.3)); width: 100%;}

    }
    .content-signin {
        float: right;width: 46.3%; background: $c_ylw; position: relative;
        &:after {content: ""; left: 0; height: 30px; background: #fff; width: 100%; display: block; position: absolute; bottom: 0;}
        .v-text {
            width: 510px; margin: auto;
            h1 {text-align: center; font-size: 60px; font-weight: 300; color: #3b3b3b; line-height: 1.3; margin-bottom: 20px;}
            p {text-align: center; font-size: 15px; color: $c_71; margin-bottom: 45px; line-height: 1.5;}
        }
        .box-form {
            .row {
                margin: 0 -10px 25px; @extend .after_clear;
                .col {
                    padding: 0 10px; @include boxSizing(border-box); float: left; width: 100%;
                    &.col5 {
                        width: 50%;
                        .btn_round {width: 100%; margin-top: 30px;}
                    }
                }
            }
        }
    }
    .pttrn {width: 250px; position: absolute; right: -4%; top: 37%;}
    &.ty-page{
        .content-signin{
            .v-text{
                text-align: center;
                h4{ font-size: 18px; font-weight: 600; color: #484848; margin-bottom: 10px; line-height: 27px;}
                p{ font-size: 15px; line-height: 23px; color: #717171; }
            }
        }
    }
}
.copyright {
    position: relative; padding: 25px 0 15px; @extend .after_clear; position: relative;

    .wrapper {padding: 0 45px;}
    &.sign {}
    p {margin: 0;}
    .cr_lft {
        float: left;
        &:before {
            content:""; left: 100px; height: 70px; top: -50px; display: block; position: absolute; background: #fff; width: 40%;
        }
    }
    .site_rh {float: right;}
}
.ls_block {
    .item {
        @extend .after_clear; margin-bottom: 110px; position: relative; z-index: 1;
        &.tagleft{
            &:before{
                right:auto; left:-200px; top: -100px;
            }
            .tag{
                position: absolute; left: -150px; right: auto; 
            }
        }
        .img {width: 46%; float: left;}
        h5{
            font-size: 12px; color: #717171; font-style: italic; line-height: 1.5; position: relative; margin-bottom: 10px; float: right; width: 46%;
            &:after {content:""; display: inline-block; vertical-align: middle; height: 1px; background: $c_grn; width: 50px; margin-left: 15px;}
        }
        .dec_item {
            float: right; width: 47.5%; position: relative;
            
        }
        &:nth-child(even) {
            .img {float: right;}
            .dec_item {float: left;}
        }
        .tag{ 
            font-family: "Poppins"; font-size: 130px; font-weight: bold; line-height: 1.51; letter-spacing: 9px; color: #5a5a5a;
            text-transform: uppercase; position: absolute; @include transform(rotate(-90deg)); right:-12%; top:auto; bottom: 0;
            transform-origin: 0px 0px; position: absolute; z-index: 3; width: auto; display: block; margin: 0; padding-right: 20px; 
            white-space: nowrap; opacity: 0.04; 
           
        }
    }
}
.wrap-bot{
    position: relative; margin-top: 140px;
    .bg-bot{ background-color: #2e2e2e;  height:175px; margin: 0 -125px;}
}
.sertifikat{
    margin: 80px 0; 
    .list{
        padding: 10px; box-sizing: border-box;
        .images{
            img{ width: 100%; }
        }
        p{ margin-top: 33px; font-family: "Poppins"; font-size: 15px; font-weight:600; color: #3e3e3e; text-align: center; line-height: 1.53; }
    }
    .slideSertifikat{
        position: relative;
        .slick-dots{ 
            position: absolute;bottom: -30px; right: 50%; transform: translateX(50%);
            li{ 
                list-style: none; display: inline-block; margin: 0 5px;
                &:before{ display:none; }
                &.slick-active{ opacity: 0.5 }
                button{ border:none; background:#FFD257; font-size: 0; width: 15px; height: 15px; border-radius: 100%; cursor: pointer; }
            }
        }
    }
}
.banner-bottom-abs{
    .tittle-content{
        h2{ color: #fff; }
    }
    figure{ 
        position: relative;  margin: 0 auto;  display: block; width: 1460px;
        .bg{ 
            background: url(../images/material/bgetos.png)no-repeat center; width: 675px; height: 100%; display: block; position: absolute;
            right: 0; bottom: 0; top: 0;
            &:before{
                content:''; position: absolute;right: 100%; top: 0; bottom: 0; background: url(../images/material/bgetos-radius.png)no-repeat center;
                width: 340px; height: 100%;
            }
         }
    }
    .text{
        position: absolute; right: 100px; top: 20%; bottom: 0; color: #fff; width: 40%;
        ul{
            li{ color: #fff; font-size: 18px; font-weight: 500;}
        }
    }
}
.bg-yellow-bot{
    position: absolute; right:0;  bottom: 0; width: 484px; height: 747px; background-color:$c_ylw;  z-index: -1;
    display: block;
    &.aboutus{ bottom: 1000px; }
}
.row-head{
     @extend .after_clear;
     .tittle-content{ float: left; }

    .right-filter{
       float: right;
       label{  font-family: "Poppins"; font-size: 15px; color: #717171; font-weight: 500;   margin-right: 10px; }
       select{
            width: 248px; height: 50px;  @include borderRadius(40px); font-size: 15px; text-align: center;
            padding: 10px 40px 10px 20px; @include boxSizing(border-box);
            background: url(../images/material/ic-keyboard-arrow-down-48px.png)no-repeat 90% center ;
            font-family: "Poppins";
       }
    }
}
.slider-client-misi{
     margin: 65px auto 130px; @extend .after_clear;width: 1520px;
     .list{
        text-align: center; float: left; position: relative;
        .col{  
            position: relative; width: 364px; height: 195px; margin: auto; display: table; border:1px solid #EDEDED;
            figure{
                display: table-cell;vertical-align: middle;position: relative;z-index: 2;
                img{display: inline-block; max-height: 100px; width: auto; @include transition(all ease-out 0.3s);}
            }
            &:hover {
                figure {
                    img {@include transition(all ease-out 0.5s); @include transform(scale(1.1));}
                }
            }
            span{
                position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 1;
                /*&:before{ content:'+'; font-size: 40px; color:#808080; position: absolute; right: -11px; top: -21px; }
                &:after{ content:'+'; font-size: 40px; color:#808080; position: absolute; right:-11px; bottom: -21px; }*/
            }
        }

        &:first-child{
            .col{
                /*&:before{ content:'+'; font-size: 40px; color:#808080; position: absolute; left: -11px; top: -21px; }
                &:after{ content:'+'; font-size: 40px; color:#808080; position: absolute; left:-11px; bottom: -21px; }*/
            }
        }
    }
    .slick-arrow{
        position: absolute; right: 0; bottom: 0; top: 52%; font-size: 0; border: none; background: url(../images/material/arrowright-grey.png)no-repeat center;
        width: 35px; height: 35px; z-index: 2; cursor: pointer;
        &.slick-prev{ top: 38%; @include transform(rotate(180deg));  }
        &.slick-disabled{ opacity: 0.5; }
    }
    .slick-list{
        padding: 15px;
    }
}
.qoutes{
    position: relative;
    .banner-client{
        position: relative; text-align: center; padding: 80px 0 0; @include boxSizing(border-box);
        h2{ font-size:55px;  font-weight: 300;  position: relative; z-index: 1; }
        b{ display: block; font-weight: bold; }
    }
    .tag{ font-family: "Poppins"; font-size: 90px; font-weight: bold; line-height: 1.51; letter-spacing: 9px; color: #5a5a5a; text-transform: uppercase; position: absolute; @include transform(rotate(-90deg)); left: -150px; bottom: -88.5px; transform-origin: 0px 0px; top:auto; position: absolute; z-index: 3; width: auto; display: block; margin: 0; padding-right: 20px; white-space: nowrap; opacity: 0.04; }
    .bg{
        background:url(../images/material/bg-slider-client.png)no-repeat center; margin-right: -100px; display: block; position: absolute;
        right: -250px; top: 0; bottom: 0; left: 0; display: block; background-size: 100%; display: block;
        &:before{
            content:''; position: absolute; left: -12px; bottom: -12px; background:#F8F3F2; width: 100%; height: 100%; z-index: -2;
        }
        &:after{
            content:''; position: absolute; left: -40px; top: -40px; background: url(../images/material/pattern-banner.png)no-repeat left;
            height: 348px; width: 100%;
        }
    }
    .slider-client-qoutes{
        width: 775px; text-align: center; margin:65px auto 0; padding: 0 40px; @include boxSizing(border-box); position: relative; z-index: 1;
        .list{
            display: inline-block; text-align: center; padding-bottom: 20px; @include boxSizing(border-box);
            &.slick-current{
                position: relative;
                &:after{ position: absolute; content: ""; height: 10px; width: 100%; background: #EC1D24; bottom: 0px; left: 0; }
            }
            img{ display: inline-block; }
        }
        .slick-arrow{
            position: absolute; left: 0; top: 10%;  border:none; background: url(../images/material/arr-slider-client.png)no-repeat center;
             font-size: 0; width: 15px; height: 15px; cursor: pointer;
             &.slick-next{
                @include transform(rotate(180deg)); right: 0; left: auto;
             }
        }
    }
    .slider-qoutes{
         text-align: center;
        .list{
            padding: 75px 125px 95px; position: relative;
            &:before{ 
                content:'“'; position: absolute; right: auto; left: 5%; top: 10%; color: #C9C9C9; font-size: 150px; display: block;  
                font-weight: bold; font-family: open sans; line-height: 150px;
            }
            &:after{ 
                content:'“'; position: absolute; left: auto; right: 5%; bottom:5%; color: #C9C9C9; font-size: 150px; display: block;  
                font-weight: bold; font-family: open sans; line-height: 150px;
            }
        }
        p{ 
            font-size:18px; font-style: italic; line-height: 24px; position: relative;
            
        }
        .author{ font-size:15px; color: #717171; font-style: italic; font-weight: 600; display: inline-block; margin-top: 40px;  }
        strong{ color: #EC1D24; font-style:normal; font-weight: bold; }
    }
}

.detail-page {
    @extend .after_clear; position: relative; margin-bottom: 100px;
    &.det-event {
        .lf-content {
            padding-right: 200px;
            p{ margin-left: 0; }
            img{ width: 100%; margin-bottom: 20px; }
            iframe{ width: 100%; height: 456px; }
        }
    }
    .lf-content {
        float: left; padding: 0 150px 0 125px; @include boxSizing(border-box); width: 66.7%; background: #fff;; position: relative;
        > h1 {font-size: 40px; line-height: 1.38; font-weight: 700; color: #404040; margin-bottom: 30px;}
    }
    .rh-content {float: right; width: 33.3%; @include boxSizing(border-box); padding: 80px 0 0px 88px;}
    .wrp-text {
        @extend .flex-list; padding-bottom: 50px; border-bottom: 1px solid #eaeaea; margin-bottom: 70px;
        .column {
            width: 47%; margin-right: 6%;
            &:nth-child(2n) {margin-right: 0;}
            .std-content {
                h6 {font-size: 16px; font-weight: 600; color: #4e4e4e; margin-bottom: 20px;}
                ul,ol {margin-left: 0px;}
            }
        }
    }
    .dt-event {margin-bottom: 30px; margin-top: -10px;}
}

.list_box {
    .box {
        background: #fff; margin: 0 15px 50px; @include borderRadius(6px); padding: 45px;
        &:last-child {margin-bottom: 0;}
        .btn_round {min-width: 250px;}
        .center {margin-top: 40px;}
        .title-little {margin-bottom: 40px;}
        h6 {font-size: 16px; font-weight: 600; margin-bottom: 15px;}
        ol,ul {margin-left: 0;}
    }
}
.list_news {
    .news {
        position: relative; padding-bottom: 30px; border-bottom: 1px solid rgba(195, 195, 195, 0.5); margin-bottom: 40px;
        &:last-child {padding-bottom: 0; margin-bottom: 0; border-bottom: none;}
        a {
            figure {
                display: block; overflow: hidden; margin-bottom: 22px;
                img {width: 100%;@include transition(all ease-out 0.3s); position: relative; display: block;}
            }
            h3 {font-size: 20px; line-height: 1.35; color: #404040; font-weight: 700;}
        }
        &:hover {
            figure {
                img {@include transition(all ease-out 0.5s); @include transform(scale(1.1));}
            }
            h3 {
                color: #000;
            }
        }
    }
}

.dt-event {
    font-size: 15px; color: #43bac7; font-style: italic; line-height: 1.53; position: relative; margin-bottom: 11px;
    &:after {content:""; display: inline-block; vertical-align: middle; height: 1px; background: #43bac7; width: 50px; margin-left: 15px;}
}
.sub-detail {font-size: 18px; font-weight: 600; line-height: 1.5; color: #717171; margin-bottom: 30px;}
.btm_share {padding-top: 50px; border-top: 1px solid #eaeaea; margin-top: 50px;}
.share {
    position: relative;
    label {display: inline-block; padding-left: 25px; background: url('../images/material/ico_share.png') no-repeat left center; line-height: 20px;}
    ul {
        display: inline-block; list-style: none; padding-left: 0px; margin-left: 20px;
        li {
            display: inline-block;list-style: none; margin: 0 8px; vertical-align: middle;
            &:before{ display:none; }
            a {
                width: 20px; height: 20px; display: block; font-size: 0;
                &:hover {background-position: bottom center !important;}
            }
            &:last-child {margin-right: 0;}
        }
    }
}
.slideNews {
    margin-left: -180px; position: relative; margin-right: -15px; margin-bottom: 100px;
    .slide {
        margin: 0 15px; position: relative; @include boxShadow(0 0px 15px -2px rgba(0,0,0,0.15));
    }
    .slick-arrow {
        position: absolute; width: 15px; height: 15px; display: block; background: url('../images/material/arr-slider-client.png') no-repeat; padding: 0;
        font-size: 0; border: none; opacity: 0.6; bottom: -30px; left: 65px; cursor: pointer;
        &.slick-next {left: 105px; @include transform(rotate(-180deg));}
        &.slick-disabled {opacity: 0.35;}
    }
}

.map-banner {
    position: relative;
    .find-loc {
        position: absolute; width: 480px; padding: 116px 100px; background: $c_ylw; top: 0; bottom: 0; @include boxSizing(border-box);
        h3 {font-size: 60px; line-height: 1.08; font-weight: 300; color: #525252; padding-bottom: 15px; border-bottom: 1px solid rgba(195, 195, 195, 0.5); margin-bottom: 30px;}
        .box-form {
            .row {
                margin-bottom: 20px;
                p {color: #717171;}
                .col {
                    .btn_round {width: 100%;}
                }
            }
        }
    }
    .map-wrap {
        height: 630px; padding-left: 480px;
        iframe {height: 100%;}
    }

}

.wrp-contact {padding-bottom: 130px;}
.top-title {
    @extend .after_clear; margin-bottom: 70px;
    .left, .right {width: 60%; @include boxSizing(border-box);}
    .left {width: 32%;}
    .right {padding-left: 27px;}
    .filter-top {
        float: right; padding-top: 70px;
        .box-form {
            .col {
                width: auto; margin-left: 27px; min-width: 160px;
                select {background-position: 90%;}
                &.lbl {
                    width: auto; padding-top: 12px; padding-right: 0; margin-left: 0;
                    label {padding: 0;}
                }
            }
        }
    }
}

.banner-full {
    figure {
        position: relative;
        &:after {content:""; left: 0; right: 0; top: 0; bottom: 0; position: absolute; display:  block; background: rgba(67,186,199,0.6); }
    }
    .mcu-wrap {
        width: auto; margin: -90px 100px 0; padding: 0 125px; position: relative;  @extend .flex-list;
        .ic_user {
            @include borderRadius(50%); width: 182px; overflow: hidden; border: 3px solid #43bac7;
            img { width: 185px; display: block; height: 185px; overflow: hidden; object-fit: cover; }
            .input_file{ display: none !important; }
            .upload-button{
                position: relative; cursor: pointer;
                &:hover{
                    &:before{
                        content:"";position:absolute; background: #333 url(../images/material/ico-camera.png) center no-repeat; opacity: 0.8; width: 100%; height: 100%; z-index: 5;
                    }
                }
            }
        }
        .info_user {
            padding: 15px 0 15px 50px;
            h4 {font-size: 45px; color: #fff; font-weight: 300; line-height: 1.36; margin-bottom: 30px;}
            .inf {
                @extend .flex-list;
                div {
                    margin-right: 35px; font-weight: 600; color:#717171; font-size: 15px;
                    label {font-size: 15px; font-weight: 600; color: #ec1d24; padding-left: 35px; line-height: 24px; display: inline-block; margin-right: 5px;}
                    &.mail {
                        font-style: italic; font-weight: 500;
                        label {background: url('../images/material/ic_mail.png') no-repeat left center;background-size: 24px; font-style: normal; font-weight: 600;}
                    }
                    &.ident {
                        label {background: url('../images/material/ico-user.png') no-repeat left center;}
                    }
                }
            }
        }
    }
}
.unduh_round {
    font-size: 15px; color: #717171; padding: 0 50px 0 25px; line-height: 50px; border: 1px solid #eaeaea; @include boxShadow(0 5px 10px -2px rgba(0, 0, 0, 0.1)); @include borderRadius(25px);
    position: relative;
    i {width: 22px; height: 22px; background: url('../images/material/download.png'); position: absolute; right: 25px; top: 0; bottom: 0; margin: auto;}
}

.list_laporan {
    @extend .flex-list;
    .ls {
        width: 30%; margin-right: 3.33%; padding: 25px; @include boxSizing(border-box); margin-bottom: 40px; border-top: 1px solid #43bac7;
        &:nth-child(3n) {margin-right: 0;}
        p{ margin-left: 0; }
        h3 {
            font-size: 20px; font-weight: 600; line-height: 1.5; color: #454545; margin-bottom: 20px;
            span {display: block;}
        }
        .unduh_round {display: block;margin-top: 40px;}
    }
}

.wrap-facilities {
    &:before {
        content:''; position: absolute; bottom: 0; left: 0; right: 0px; background: #43bac7 url('../images/material/bg_facilities.png') no-repeat center;
        height: 48%;
    }
}
.slideFacilities {
    position: relative;
    .slick-list {padding: 0 -125px; margin: 0 -125px;}
    .slide {
        position: relative; padding: 20px 125px; @include boxSizing(border-box); background: transparent;
        .count-dot {font-size: 200px; opacity: 0.4; color: #eaeaea; font-weight: 700; position: absolute; right: 40px; top: 50px;}
        .bx-slide {
            @extend .flex-list; background: #fff; @include boxShadow(0 3px 20px -2px rgba(0,0,0,0.1));
            figure,.dec-facilities {width: 50%;}
            .dec-facilities {
                padding: 90px; @include boxSizing(border-box);
                .title-pg {margin-left: -28.5px;}
                .txt {position: relative; @include transform(translateY(-50%)); top: 50%;}
            }
        }
    }
}
.thumbfacilities {
    width: 895px; margin: 0 auto; position: relative; padding: 65px 0;
    a {
        margin: 0 16px; border: 1px solid transparent; position: relative;
        &:after {content:""; background: rgba(67,186,199,0.7); display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0;}
        &.slick-current {
            border-color: #ec1d24;
            &:after {opacity: 0;}
        }
    }
    .slick-arrow {
        position: absolute; width: 35px; height: 35px; background: url('../images/material/arrowright-white.png') no-repeat; background-size: 100%;
        border: none; font-size: 0; top: 0; bottom: 0; right: -50px; left: auto; margin: auto; cursor: pointer;
        &.slick-prev {right: auto; left: -50px; @include transform(rotate(-180deg));}
        &.slick-disabled {opacity: 0.6;}
    }
}

.wrap-lab {
    position: relative;
    .wrp-images {
        width: 861px; margin: auto; position: relative;padding-top: 270px;
        figure {height: 865px; @include boxSizing(border-box); padding: 197px 41px 0; background-size: 100% !important;}
    }
    .wrp-absolute {
        position: absolute; top: 0; left: 0; right: 0;
        .abs {
            position: absolute; padding-bottom: 50px; @extend .flex-list; width: 300px;
            h5 {font-size: 25px; line-height: 1.36; font-family: $f_open; color: #3b3b3b;@include boxSizing(border-box);}
            &.img1 {
                top: 235px; left: -180px; padding-bottom: 0; width: 330px;
                .dot{left: auto; bottom: 0px; @include transform(rotate(-55deg)); right: -20px;}
                h5 {width: 40%; padding: 40px 30px 0 0;}
                .img {width: 170px;}
            }
            &.img2 {
                left: 0; right: 0; left: 50%; @include transform(translateX(-50%)); text-align: center;
                h5 {margin-bottom: 20px; width: 100%; text-align: center;}
                .img {display: block; margin: auto;}
                
            }
            &.img3 {
                left: auto; right: -270px; top: 245px; padding-bottom: 0; width: 380px;
                h5 {order: 2; -webkit-order: 2;-moz-order: 2; width: 40%; padding-left: 25px;}
                .img {order: 1; -webkit-order: 1;-moz-order: 1; width: 197px;}
                .dot{left: -50px; bottom: -20px; @include transform(rotate(55deg)); right: auto;}
            }
        }
        .dot {
            width: 22px; height: 22px; @include borderRadius(50%); background: #ec1d24; position: absolute; bottom: 0px; left: 0; right: 0; margin: auto;
            &:after {height: 20px; content: ""; width: 2px; background: #ec1d24; position: absolute; top: -90%; display: block; left: 0; right: 0; margin: auto;}
        }
    }
}

.ls_faci {
    margin: 0 !important; @extend .flex-list; justify-content: center; padding-bottom: 30px; text-align: center;
    li {
        width: 230px; margin: 0 30px 20px 0 !important; color: #454545; font-weight: 700; padding-right: 20px; @include boxSizing(border-box); line-height: 24px;
        display: inline-block; vertical-align: top; text-align: left;
        &:nth-child(5n) {margin-right: 0 !important;}
    }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  -webkit-appearance: none;  margin: 0;  }
input[type=number] {
    -moz-appearance:textfield;
}
.bg_bf {
    height: 430px; position: absolute; bottom: 0; left: 0; right: 0; background: #f8f8f8 url('../images/material/bg_facibg.png') no-repeat; display: block; z-index: -1;
    &:after {height: 250px; position: absolute; top: -250px; background: #ec1d24; content: ""; left: 0; right: 0; display: block;}
}
.wrap_pop {
    position: fixed; height: 100%; top: 0; right: 0; bottom: 0; left: 0; width: 100%;
    background: rgba(0,0,0,0.9); z-index: 9999; display: none; overflow-y: auto;
    .pop_inner {
        position: absolute; display: table; height: 100%; width: 100%;
        .overlay_pop {position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; z-index: 7;}
        .box_pop {display: table-cell; vertical-align: middle;}
        .content_pop {
            width: 900px; margin: 3% auto; position: relative; z-index: 8; @include boxSizing(border-box);
            .close {
                width: 50px; height: 50px; background: #bb0a1a url('../images/material/close_popup.png') no-repeat right; display: inline-block;
                position: absolute; top: -50px; right: 0px; z-index: 2; color: #fff; line-height: 55px; font-weight: 600; font-style: italic; padding-left: 40px; box-sizing: border-box; font-size: 18px;
            }
            .img_vid{
                img{ width: 100%; }
                iframe{ width: 100%; height: 500px; }
            }
            .wrap_img {
                margin-bottom: 30px;
                img {display: block; margin: auto;}
                iframe {height: 340px;}
            }
            .text {
                h3 {font-size: 24px; color: #111; font-weight: 700; margin-bottom: 10px;}
                h6 {color: #f99d1c; margin-bottom: 20px; font-size: 16px;}
                ul {
                    li {
                        position: relative; padding-left: 25px; font-size: 18px; line-height: 30px; font-weight: 300;
                        &:before {
                            content:''; width: 8px; height: 8px; background: #f6881f; display: block; position: absolute; left: 0; top: 10px;
                        }
                    }
                }
                p {font-size: 18px; line-height: 30px; font-weight: 300;}
            }
            .wrappop_text {
                padding: 0 25px;
            }
            .capt{ width: 900px; text-align: center; margin: 0 auto; padding: 20px 35px; background: #fff; box-sizing: border-box; font-weight: 600; font-style: italic; font-size: 18px; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%);}
            .slide{ position: relative; }
        }
    }
    .slide_Thumb{
        margin: 0 auto; background: #fff; width: 900px; padding: 0 50px; box-sizing: border-box; padding-bottom: 30px;position: relative; top: -1px;
        .thumb{ margin: 0 15px; }
    }
    .galery_pop{
        position: relative;
        .slick-arrow{
            position: absolute; top: 35%; margin:auto; border: 0; background: none; font-size: 0; z-index: 1;
            &.slick-prev{ background: url(../images/material/arrow-left.jpg) no-repeat; height: 35px; width: 25px; left:25px; }
            &.slick-next{ background: url(../images/material/arrow-right.jpg) no-repeat; height: 35px; width: 25px; right: 25px; }
            &.slick-disabled{ opacity: 0.5; }
        }
    }
    &.video{
        .slide_Thumb{ display: none; }
    }
}
.branch-address{
    background: #2e2e2e; height: 100%; padding: 30px; color: #fff;
    @extend .after_clear;
    .title{ font-weight: bold; font-size: 24px; margin-bottom: 20px; }
    .col{
        display: inline-block; vertical-align: top; box-sizing: border-box; position: relative; padding: 7px 36.5px 7px 5px; margin-left: 40px;
        @extend .after_clear;
        &:first-child{ 
            padding-left:5px; margin-left: 0; width: 100%;
        }
        &:after{ position:absolute; content: ""; height: 25px; width: 2px; background: #ec1d24; top: 0; left: 0; }
        p{ 
            font-family: 'Poppins'; font-size: 13px; line-height: 1.54; 
            b{ font-weight: 600; }
        }
        .left{ width: 60%; }
        .right{ width: 40%; }
        ul li{ 
            font-family: 'Poppins'; font-size: 13px; line-height: 1.54; position: relative; padding-left: 20px; box-sizing: border-box; 
            &:before{ position:absolute; content: ""; background: url(../images/material/phone.png) no-repeat bottom; width: 14px; height: 14px; left: 0; top: 3px; }
            &:first-child{
                &:before{
                    background: url(../images/material/phone.png);
                }
            }
            &:last-child{
                &:before{
                    background: url(../images/material/email.png) no-repeat bottom;
                }
            }
        }
    }
}
.pop_inner .content_pop .galery_pop{ display: none; }
.pop_inner .content_pop .galery_pop.active{ display: block; }
.static-pages{
     &.bg-yellow{
        background: #FFFF00;
     }
     &.bg-white{ background: #fff; }
     &.no-white{ 
        .wrapper{
            padding: 0;
            .images{
                .bg-img{ height: 100vh; }
            }
        }
     }
     .wrapper{ margin: 0 auto; padding: 70px 70px 70px 70px; box-sizing: border-box; position: relative; }
     .images{
        position: relative;
        .bg-img{ height: 900px; background-size: 100%; object-fit: cover;  }
     }
     .content{ 
        position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center; width: 700px;
        h1{ font-size: 70px; line-height: 75px; color: #383838; margin-bottom: 30px; }  
        p{ color: #717171; font-size: 15px; line-height: 23px; }
     }
}
.search-result{
    .wrapper{
        margin: 0 245px;
    }
    .search-eng{
        background: #F5F5F5; text-align: center; padding: 60px 0; box-sizing: border-box;
        .wrapper{ margin: 0 455px; width: auto; }
        .col{  
            display: inline-block;  width: auto;
            label{ padding-top: 15px; box-sizing: border-box; }
            form {position: relative;}
            input[type="text"] { width: 700px;font-family: 'Poppins'; font-style: normal; }
            @include placeholder {color: #7B7B7B;}
            input[type="submit"] {
                position: absolute; width: 65px;padding: 0; right: 0; bottom: 0; left: auto; top: 0; border: none; font-size: 0;
                background: url('../images/material/button_src_big.png') no-repeat center; cursor: pointer;
            }
        }
    }
    .title{
         font-size: 30px; line-height: 1.36; color: #3b3b3b; position: relative;padding-left: 28.5px; box-sizing: border-box; font-weight: 300; margin-bottom: 20px; margin-top: 65px;
        &:before{ position:absolute; content: ""; background: #ec1d24; height: 35px; width: 2px; left: 0; top: 0px; }
    }
    .list-search{
        font-family: "Poppins";color: #7B7B7B;
        .col{
             padding-bottom: 20px; box-sizing: border-box; border-bottom: 1px solid #ccc; margin-bottom: 30px;
        }
        h5{ font-size: 17px; line-height: 26px;  font-weight: 600; }
        p{ 
            font-size: 15px;  line-height: 23px;
            span{ background: #ff0; }
        }
    }
}
:root {
  --tooltip-bg: #5a5a5a;
}
.tooltips {
  position: relative; display: inline-block;
}

.tooltips:hover::after {
  text-align: center; content: attr(data-tooltip); position: absolute; width: 250px; z-index: 2; padding: 5px; border-radius: .25rem; background: var(--tooltip-bg); color: #fff; font-size: 12px; bottom: 20px; left: 0;
}
/* footer
----------------------------------------------------------------------------------------------*/
footer{
    position: relative;
    .wrapper{ margin: 0 100px; }
    &:before{ position:absolute; content: ""; background: $c_ylw; height: 246px; width: 957px; bottom: 0; left: 0; z-index: -1; }
    .darkbox{
        width: auto; margin: 0 auto; background: #2e2e2e; padding: 33px 128.5px 64px 128.5px; box-sizing: border-box;
        .top-footer{
            width: 100%; text-align: center; height: 42px; position: relative; margin-bottom: 53.5px;
            &:after{ position:absolute; content:""; background: #fff; width: 100%; height: 1px; bottom: 0; left: 0; }
            ul li{
                display: inline-block; padding: 0 30px 8.5px 30px; box-sizing: border-box; position: relative;
                &:after{ position:absolute; content: ""; background: #fff; width: 2px; height: 100%; right: 0;}
                &:last-child{
                    &:after{ display:none; }
                }
                a{ font-family: 'Poppins'; font-weight: 600; line-height: 1.53; color: #fff; }
            }
        }
        .middle-footer{
            padding: 0 15px; box-sizing: border-box;
            .col{
                display: inline-block; color: #fff; vertical-align: top; box-sizing: border-box; position: relative; padding: 7px 36.5px 7px 5px; margin-left: 40px;
                @extend .after_clear;
                &:first-child{ 
                    padding-left:5px; margin-left: 0;
                    &:before{ position:absolute; content: ""; background: #fff; right: 0; height: 100%; width: 1px; top: 0; }
                }
                &:nth-child(2){ 
                    margin-left: 40px; 
                    a{ color: #fff; font-weight: bold; }
                    p{ margin-bottom: 10px; }
                }
                &:after{ position:absolute; content: ""; height: 25px; width: 2px; background: #ec1d24; top: 0; left: 0; }
                .title{ font-size: 17px; font-weight: bold; line-height: 1.35; color:#ffd257; margin-bottom: 7px;}
                p{ 
                    font-family: 'Poppins'; font-size: 13px; line-height: 1.54; 
                    b{ font-weight: 600; }
                }
                .left{ margin-right: 50px; }
                ul li{ 
                    font-family: 'Poppins'; font-size: 13px; line-height: 1.54; position: relative; padding-left: 20px; box-sizing: border-box; 
                    &:before{ position:absolute; content: ""; background: url(../images/material/phone.png) no-repeat bottom; width: 14px; height: 14px; left: 0; top: 3px; }
                    &:first-child{
                        &:before{
                            background: url(../images/material/phone.png);
                        }
                    }
                    &:last-child{
                        &:before{
                            background: url(../images/material/email.png) no-repeat bottom;
                        }
                    }
                }
                .socmed{
                    li{
                        display: inline-block; padding: 0; padding-right: 13.8px;
                        &:before{ display:none; }
                    }
                }
            }
        }
    }
    .bottom-footer{
        margin:17px 146px 0 120px ;
        @extend .after_clear;
        p{ font-size: 11px; line-height: 1.55; color: #414141; }
    }
}
